/* eslint-disable no-console */
import type {
  AddPaymentInfoEvent,
  AddToCartEvent,
  DebugInit,
  ProviderFireable,
  ProviderInterface,
  PurchaseEvent,
  RemoveFromCartEvent,
  ViewItemEvent,
} from '../types';

export const DEBUG_TYPE = 'debug';

export class DebugProvider implements ProviderInterface, ProviderFireable {
  id = '';
  type = DEBUG_TYPE;

  constructor(data?: DebugInit) {
    if (data) {
      this.init(data);
    }
  }

  init(data: DebugInit) {
    const { id } = data;
    this.setup(id);
  }

  isValid() {
    return this.id.length > 0;
  }

  setup(id: string) {
    this.id = id;
  }

  viewItem(data: ViewItemEvent) {
    console.log(data);
  }

  addToCart(data: AddToCartEvent) {
    console.log(data);
  }

  removeFromCart(data: RemoveFromCartEvent) {
    console.log(data);
  }

  addPaymentInfo(data: AddPaymentInfoEvent) {
    console.log(data);
  }

  purchase(data: PurchaseEvent) {
    console.log(data);
  }
}
