import type {
  AddPaymentInfoEvent,
  AddToCartEvent,
  GoogleInit,
  Product,
  ProviderInterface,
  PurchaseEvent,
  RemoveFromCartEvent,
  ViewItemEvent,
} from '../types';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    dataLayer: any;
  }
}

export class GoogleProvider implements ProviderInterface {
  id = '';
  type = 'google';
  hasScript = false;

  constructor(data?: GoogleInit) {
    if (data) {
      this.init(data);
    }
  }

  init(data: GoogleInit) {
    const { ga, gtm } = data;
    this.id = `${ga}${gtm}`;
    this.setup(ga, gtm);
  }

  isValid() {
    return this.hasScript && this.id.length > 0;
  }

  setup(ga?: string, gtm?: string) {
    if (gtm) {
      const gtmScript = document.createElement('script');
      gtmScript.async = true;
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtm}`;
      document.head.appendChild(gtmScript);
    }

    if (ga) {
      const gaScript = document.createElement('script');
      gaScript.async = true;
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${ga}`;
      document.head.appendChild(gaScript);
    }

    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      // Arguments object is required for gtag/ga to work
      window.dataLayer.push(arguments); // eslint-disable-line prefer-rest-params
    };

    if (gtm) {
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
    }

    if (ga) {
      window.gtag('js', new Date());
      window.gtag('config', ga);
    }

    this.hasScript = true;
  }

  // Google Ecommerce events: https://developers.google.com/analytics/devguides/collection/ga4/set-up-ecommerce
  viewItem(data: ViewItemEvent) {
    const event = {
      currency: data.currency,
      value: data.products.reduce((s, p) => s + p.price, 0),
      items: [],
    };

    data.products?.forEach((product: Product, index: number) => {
      const item = {
        item_id: product.sku ?? product.id,
        item_name: product.name,
        price: product.price,
        quantity: product.quantity,
        index,
      };

      event.items.push(item);
    });

    window.gtag('event', 'view_event', event);
  }

  addToCart(data: AddToCartEvent) {
    const event = {
      currency: data.currency,
      value: data.value,
      items: {
        item_id: data.product.sku ?? data.product.id,
        item_name: data.product.name,
        affiliation: '',
        coupon: data.product.coupon ?? '',
        discount: data.product.discount ?? 0.0,
        index: 0,
        item_brand: '',
        item_category: data.product.category ?? '',
        price: data.product.price,
        quantity: data.product.quantity,
        currency: data.product.currency,
      },
    };

    window.gtag('event', 'add_to_cart', event);
  }

  removeFromCart(data: RemoveFromCartEvent) {
    const event = {
      currency: data.currency,
      value: data.value,
      items: {
        item_id: data.product.sku ?? data.product.id,
        item_name: data.product.name,
        affiliation: '',
        coupon: data.product.coupon ?? '',
        discount: data.product.discount ?? 0.0,
        index: 0,
        item_brand: '',
        item_category: data.product.category ?? '',
        price: data.product.price,
        quantity: data.product.quantity,
        currency: data.product.currency,
      },
    };

    window.gtag('event', 'remove_from_cart', event);
  }

  addPaymentInfo(data: AddPaymentInfoEvent) {
    const event = {
      currency: data.currency,
      value: data.value,
      items: [],
    };

    data.products?.forEach((product: Product, index: number) => {
      const item = {
        item_id: product.sku ?? product.id,
        item_name: product.name,
        affiliation: '',
        coupon: product.coupon ?? '',
        discount: product.discount ?? 0,
        index,
        item_brand: '',
        item_category: product.category ?? '',
        price: product.price,
        quantity: product.quantity,
        currency: product.currency,
      };

      event.items.push(item);
    });

    window.gtag('event', 'add_payment_info', event);
  }

  purchase(data: PurchaseEvent) {
    const event = {
      transaction_id: data.orderId ?? '',
      value: data.value,
      tax: data.tax ?? 0.0,
      shipping: data.shipping ?? 0.0,
      currency: data.currency,
      coupon: data.coupon ?? '',
      items: [],
    };

    data.products?.forEach((product: Product, index: number) => {
      const item = {
        item_id: product.sku ?? product.id,
        item_name: product.name,
        affiliation: '',
        coupon: product.coupon ?? '',
        discount: product.discount ?? 0.0,
        index,
        item_brand: '',
        item_category: product.category ?? '',
        price: product.price,
        quantity: product.quantity,
        currency: product.currency,
      };

      event.items.push(item);
    });

    window.gtag('event', 'purchase', event);
  }
}
