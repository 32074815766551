/* eslint-disable no-console */
import { providers } from './providers';
import { DEBUG_TYPE } from './providers/debug';
import {
  ProviderInit,
  ProviderInterface,
  SupportedEvent,
  SupportedEvents,
} from './types';

type ProviderList = {
  type: string;
  provider: ProviderInterface;
};

class Tracker {
  connections: ProviderList[] = [];
  debugLogging: boolean = false;

  init(type: string, data: ProviderInit) {
    const alreadyInitialized = this.connections.some((c) => c.type === type);
    if (!alreadyInitialized) {
      const instances: ProviderInterface[] = Object.values(providers).map(
        (PossibleProvider) => new PossibleProvider(),
      );

      instances.forEach((provider) => {
        if (provider.type === type) {
          provider.init(data);
          this.connections.push({ type: provider.type, provider });
          if (provider.type === DEBUG_TYPE) {
            this.debugLogging = true;
          }
        }
      });
    }
  }

  fire(eventType: SupportedEvent, data: any) {
    this.connections.forEach(({ provider }: ProviderList) => {
      if (provider.isValid() && typeof provider[eventType] === 'function') {
        if (this.debugLogging) {
          console.log(`firing ${eventType} for ${provider.type}`);
        }
        provider[eventType](data);
      } else if (this.debugLogging) {
        console.log(
          provider.type,
          provider.isValid(),
          typeof provider[SupportedEvents[eventType]],
        );
      }
    });
  }
}

export const tracker = new Tracker();
