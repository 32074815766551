import type {
  AddPaymentInfoEvent,
  AddToCartEvent,
  MetaInit,
  ProviderFireable,
  ProviderInterface,
  PurchaseEvent,
  RemoveFromCartEvent,
  ViewItemEvent,
} from '../types';

declare global {
  interface Window {
    fbq: (...args: any[]) => void;
  }
}

export class MetaProvider implements ProviderInterface, ProviderFireable {
  id = '';
  type = 'meta';
  hasScript = false;
  fbWindow = window;

  constructor(data?: MetaInit) {
    if (data) {
      this.init(data);
    }
  }

  init(data: MetaInit) {
    const { id } = data;
    this.id = id;
    this.setup(id);
  }

  isValid() {
    return this.hasScript && this.id.length > 0;
  }

  setup(id: string) {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
    `;

    document.head.appendChild(script);
    this.fbWindow.fbq('init', `${id}`);
    this.fbWindow.fbq('track', 'PageView');
    this.hasScript = true;
  }

  // Reference for custom events: https://developers.facebook.com/docs/meta-pixel/reference/
  // Possibly add PII https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching
  viewItem(data: ViewItemEvent) {
    const { products, currency } = data;

    const items = products.map((product) => ({
      id: product.sku ?? product.id,
      name: product.name,
      quantity: product.quantity,
      price: product.price,
    }));

    const event = {
      content_ids: products.map((p) => p.sku ?? p.id),
      content_name: products[0].name,
      content_type: 'product',
      contents: items,
      currency,
      value: data.value,
    };

    this.fbWindow.fbq('track', 'ViewContent', event, { eventID: data.eventId });
  }

  addToCart(data: AddToCartEvent) {
    const { product, currency } = data;

    const items = [
      {
        id: product.sku ?? product.id,
        name: product.name,
        quantity: product.quantity,
        price: product.price,
      },
    ];

    const event = {
      content_ids: [product.id],
      content_name: product.name,
      content_type: 'product',
      contents: items,
      currency,
      value: product.price,
    };

    this.fbWindow.fbq('track', 'AddPaymentInfo', event, {
      eventID: data.eventId,
    });
  }

  removeFromCart(data: RemoveFromCartEvent) {
    const { product, currency } = data;

    const items = [
      {
        id: product.sku ?? product.id,
        name: product.name,
        quantity: product.quantity,
        price: product.price,
      },
    ];

    const event = {
      content_ids: [product.id],
      content_name: product.name,
      content_type: 'product',
      contents: items,
      currency,
      value: product.price,
    };

    this.fbWindow.fbq('track', 'AddPaymentInfo', event, {
      eventID: data.eventId,
    });
  }

  addPaymentInfo(data: AddPaymentInfoEvent) {
    const { products, currency } = data;

    const items = products.map((product) => ({
      id: product.sku ?? product.id,
      name: product.name,
      quantity: product.quantity,
      price: product.price,
    }));

    const event = {
      content_category: products[0].category ?? '',
      content_ids: products.map((p) => p.sku ?? p.id),
      contents: items,
      currency,
      value: data.value,
      user_data: { em: data.customer?.email },
    };

    this.fbWindow.fbq('track', 'AddPaymentInfo', event, {
      eventID: data.eventId,
    });
  }

  purchase(data: PurchaseEvent) {
    const { products, currency } = data;

    const items = products.map((product) => ({
      id: product.sku ?? product.id,
      name: product.name,
      quantity: product.quantity,
      price: product.price,
    }));

    const event = {
      user_data: { em: data.customer?.email },
      order_id: data.orderId,
      content_ids: products.map((p) => p.sku ?? p.id),
      content_name: products[0].name,
      content_type: 'product',
      contents: items,
      currency,
      value: data.value,
    };

    this.fbWindow.fbq('track', 'Purchase', event, { eventID: data.eventId });
  }
}
